/* Pubblicazione.css */

.pubblicazione-container {
  padding: 2rem;
  margin-top: 80px;
  color: white;
}

.pubblicazione-container h1 {
  text-align: center;
  color: #Ff7514;
  margin-bottom: 2rem;
}

.titlePubblicazione{
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button{
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1em;
  padding: 10px;
  cursor: pointer;
  transition: all .3s ease;
}

.form-container-pubblicazione{
  display: flex;
  justify-content: space-evenly;
  min-height: 40vh; 
  padding: 20px; 
  margin: 100px auto;
  background-color: #151616;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width: 1000px;
  height: auto;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 2rem;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #45a049;
}

.manifestazioni-table {
  overflow-x: auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

th {
  background-color: rgba(255, 117, 20, 0.2);
  color: #Ff7514;
  font-weight: 600;
}

.table-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.edit-button,
.delete-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.edit-button {
  background-color: #Ff7514;
  color: white;
}

.edit-button:hover {
  background-color: #e66a0f;
}

.delete-button {
  background-color: rgba(255, 68, 68, 0.8);
  color: white;
}

.delete-button:hover {
  background-color: rgba(255, 68, 68, 1);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  color: white;
}

.error-message {
  color: #ff4444;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
}

@media (max-width: 768px) {
  .pubblicazione-container {
    padding: 1rem;
  }

  .manifestazioni-table {
    margin: 0 -1rem;
    border-radius: 0;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons button {
    width: 100%;
  }
}
  
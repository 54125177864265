.manifestazioni-page {
  padding: 2rem;
  margin-top: 80px;
}

.manifestazioni-title {
  text-align: center;
  color: #Ff7514;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.manifestazioni-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 100px auto;
}

.manifestazione-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.manifestazione-card:hover {
  transform: translateY(-5px);
}

.manifestazione-info {
  padding: 1rem;
  color: white;
  text-align: center;
}

.manifestazione-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* Hover card style for home page */
.manifestazione-card-hover {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.manifestazione-card-hover:hover {
  transform: translateY(-5px);
}

.manifestazione-card-hover .manifestazione-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.manifestazione-card-hover:hover .manifestazione-image {
  filter: brightness(30%);
}

.manifestazione-info-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
}

.manifestazione-card-hover:hover .manifestazione-info-hover {
  opacity: 1;
}

.manifestazione-info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #Ff7514;
}

.manifestazione-info p {
  font-size: 1rem;
  margin: 0.25rem 0;
}

/* Animation for info appearance */
.manifestazione-card:hover .manifestazione-info > * {
  animation: slideUp 0.3s ease forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Stagger animation for multiple elements */
.manifestazione-info h3 {
  animation-delay: 0.1s;
}

.manifestazione-info p:nth-child(2) {
  animation-delay: 0.2s;
}

.manifestazione-info p:nth-child(3) {
  animation-delay: 0.3s;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  color: white;
}

.error-message {
  color: #ff4444;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
}

@media (max-width: 768px) {
  .manifestazioni-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .manifestazioni-grid {
    grid-template-columns: 1fr;
  }
}

.search-container {
  max-width: 600px;
  margin: 100px auto;
  padding: 0 1rem;
}

.search-input {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #Ff7514;
  background: rgba(255, 255, 255, 0.15);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.no-results {
  text-align: center;
  color: white;
  padding: 2rem;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
}

@media (max-width: 480px) {
  .search-container {
    padding: 0 1rem;
  }
  
  .search-input {
    font-size: 1rem;
    padding: 0.8rem;
  }
} 
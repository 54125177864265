.imgGalleria{
 max-width: 500px;
 margin: 10px;
 border: 10px solid #Ff7514;
 border-radius: 10px;
 box-shadow: 0 0 5px 0 #Ff7514;
}

.list{
    columns: 500px;
}

@media (max-width: 500px){

    .imgGalleria{
        position: relative;
        right: 10%;
        
       }
}
.detail-container {
  min-height: 100vh;
  padding: 2rem;
}

.detail-content {
  max-width: 1200px;
  margin: 100px auto;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #33333333;
  padding: 2rem;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
}

.detail-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin: 0;
}

.detail-actions {
  display: flex;
  gap: 1rem;
}

.back-button, .edit-button, .download-button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button {
  background-color: #f0f0f0;
  color: #333;
}

.edit-button {
  background-color: #007bff;
  color: white;
}

.download-button {
  background-color: #ff7514;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.detail-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.detail-image {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.manifestazione-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.detail-info {
  padding: 1rem;
}

.info-group {
  margin-bottom: 1.5rem;
}

.info-group h3 {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.info-group p {
  color: #333;
  font-size: 1.1rem;
  margin: 0;
}

/* Loading animation */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
}

/* Error and Not Found styles */
.error-container,
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
}

.error-message,
.not-found-message {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #dc3545;
  font-size: 1.2rem;
}

.pdf-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 16px;
  color: white;
}

.download-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #Ff7514;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  text-align: center;
}

.download-button:hover {
  background-color: #e66a12;
}

.info-group {
  margin-bottom: 20px;
}

.info-group h3 {
  margin-bottom: 8px;
  color: #333;
}

.info-group p {
  margin: 0;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .detail-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .detail-grid {
    grid-template-columns: 1fr;
  }

  .detail-header h1 {
    font-size: 2rem;
  }

  .detail-actions {
    width: 100%;
    justify-content: center;
  }

  .back-button, .edit-button, .download-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

/* Hover Effects */
.back-button:hover {
  background-color: #e0e0e0;
}

.edit-button:hover {
  background-color: #0056b3;
}

.download-button:hover {
  background-color: #ff7614cb;
}

/* Animation classes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.detail-content {
  animation: fadeIn 0.5s ease-out;
}

/* Print styles */
@media print {
  .detail-actions {
    display: none;
  }

  .detail-container {
    padding: 0;
  }

  .detail-content {
    box-shadow: none;
  }
}
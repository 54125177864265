.containerFooter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: white;
    margin: 10px auto;
}

.logoFooter{
    max-width: 100px;
    height: auto;
    margin: 0 auto;
}

.copyright{
    padding: 20px;
    text-align: center;
    color: #f5f5f5;
}

.copyright a{
    text-decoration: none;
    color: white;
}

.hrMainFooter{
    margin-left: 20px;
    margin-right: 20px;
}

.hrFooter{
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 100px;
}

.titleFooter{
    position: relative;
    top: 20px;
    text-align: center;
    text-transform: uppercase;
}

.infoFooter{
    margin-top: 20px;
}


.scrolling-images-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
  }

  .scrolling-images-container ul{
    width: 100%;
    min-width: calc(250px * var(--quantity));
    position: relative;
    list-style: none
  }

  .scrolling-images-container ul li{
        width: 250px;
        position: absolute;
        left: 100%;
        animation: AutoRun var(--time) linear infinite;
        animation-delay: calc(var(--time) / var(--quantity) * (var(--index) - 1) - 9s );
  }

  .scrolling-images-container:hover li {
    animation-play-state: paused;
}

.infoFooter h3{
    margin-bottom: 5px;
}


  @keyframes AutoRun{
    to{
        left: -250px;
    }
  }

  
  .footer-image {
    margin-top: 50px;
    width: 150px;
  }
  
  
  
  



@media (max-width: 768px){
    .containerFooter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .infoFooter{
        margin: 30px;
        font-size: large;
        text-align: center;
    }

    .logoFooter{
        max-width: 150px;
    }

    .titleFooter{
        position: relative;
        top: 20px;
        text-align: center;
        text-transform: uppercase;
        font-size: x-large;
    }

    
}
.contactContainer{
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        height: 70vh;
        width: 100vw;
        position: relative;
        overflow: hidden;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.110);
        border-bottom: 5px solid #Ff7514;
}

.contactImg{
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;

}

.textHeaderUp{
    top: 100px;
    color: #f5f5f5;
    position: absolute;
}

.textHeader{
    color: #f5f5f5;
}

.maps{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border: 1px solid #151616;
}

.divDo{
    display: flex;
    flex-direction: column;
    margin-left: 400px;
    margin-right: 400px;
}

.hrDo{
    margin-top: 50px;
    margin-bottom: 20px;
    border: 1px solid #Ff7514;

}

.mainDo{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.colDo{
    text-align: center;
}

.colDo h1{
    font-size: 50px;
}



.blockMaps{
    position: absolute;
    text-align: center;
    margin-right: 700px;
    padding: 30px;
    background-color: #151616;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: #f5f5f5;
    border-radius: 10px;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh; 
    padding: 20px; 
    margin: 100px auto;
    background-color: #151616;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    max-width: 1000px;
    height: auto;
}

.form-control, .form-button {
    width: 500px;
    padding: 20px;
    margin: 10px;
    border: 2px solid #151616;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.form-control:hover, .form-button:hover {
    background-color: #151616;
    border: 2px solid #f5f5f5;
    color: #f5f5f5;
    transition: background-color 1s, color 1s;
}

.form-button {
    cursor: pointer;
    height: auto;
    background-color: #f5f5f5;
    color: #151616;
}

.white{
    color: #f5f5f5;
}

.mt20{
    margin-top: 20px;
}

.link{
    text-decoration: none;
    color: white;
}

.orange{
    color: #Ff7514;
}

.popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    background-color: #151616;
    color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 16px;
  }
  

@media (max-width: 768px){
    .form-control, .form-button {
        max-width: 300px;
        padding: 20px;
        margin: 10px;
        border: 2px solid #Ff7514;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }

    .form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px;
    }

    .textHeader{
        text-align: center;
    }

    .divDo{
        display: none;
    }
}

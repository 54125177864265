.positionChiSiamo{
    position: relative;
}

.titleChiSiamo{
    text-align: center;
    margin-top: 20px;
}

.containerChiSiamo{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.img{
    position: absolute;
    max-width: 500px;
    border: 5px solid #151616;
    border-radius: 10px;
    
}

.chiSiamoText{
 text-align: center;
 margin-top: 15px;
 max-width: 500px;
}

.chiSiamoText strong{
    color: #Ff7514;
}

.imgChiSiamo{
    max-width: 500px;
    margin: 15px;
    max-height: 500px;
    border: 3px solid #Ff7514;
    border-radius: 10px;
    
}

.imgs1, .imgs2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 800px;
}


@media (max-width: 1920px){

    .imgChiSiamo{
        max-width: 400px;
        
    }
}

@media (max-width: 1300px){

    .imgChiSiamo{
        max-width: 300px;
        
    }
}

@media (max-width: 1000px){

    .containerChiSiamo{
        flex-wrap: wrap;
    }

    .chiSiamoText{
        margin: 30px;
    }

}
html, body {
  overflow-x: hidden; 
}

.container {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.301);
  z-index: 0;
  border-bottom: 5px solid #Ff7514;    
  }

  .bgImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 1;
  }
  
  .welcomeWrite {
    text-align: center;
    color: #F5F5F5;
    z-index: 2;
    backdrop-filter: blur(2px);
  }
  
  .imgHome {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .containerGalleryText {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 100px;
  }
  
  .textDiv {
    max-width: 500px;
    padding: 20px;
    text-align: left;
    margin-bottom: 100px;
  }
  
  .gallery {
    flex-grow: 1;
    margin-right: 20px;
  }
  

  .textDiv{
    text-align: center;
    position: relative;
   top: 50px;
  }

  .gallery {
    right: 200px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    max-width: 1000px;
    perspective: 1000px;
    transition: transform 0.2s ease-out;
  }
  
  .item {
    overflow: hidden;
    position: relative;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
   
  }
  
  .item:hover .gallery-image {
    transform: scale(1.1);
  }
  
  .item1 {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  
  .item2 {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
  
  .item3 {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
  }
  
  .item4 {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
  
  .item5 {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }
  
  .item6 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  
  .item7 {
    grid-column: 2 / 2;
    grid-row: 3 / 4;
  }
  
  .item8 {
    grid-column: 1 / 2;
    grid-row: 4 / 4;
  }
  
  .item9 {
    grid-column: 2 / 2;
    grid-row: 4 / 4;
  }

  .item10 {
    grid-column: 3 / 5;
    grid-row: 3 / 5;
  }

  .flexDiv{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .colDiv{
    margin: 50px;
    display: flex;
    flex-direction: column;
  }

  .buttonDiv{
    text-decoration: none;
    color: white;
    background-color: #Ff7514;
    padding: 10px;
    position: relative;
    top: 20px;
    border-radius: 10px;
  }

  .buttonDiv:hover{
    background-color: white;
    color: #Ff7514;
    transition: 0.5s;
  }

  .titleDiv{
    text-transform: uppercase;
  }
  
  .mainBlock {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    position: relative;

  }
  
  .block {
    width: 45%;
    padding: 20px;
    text-align: center;
    position: absolute;
    bottom: -20px;
    color: white;
    border-radius: 10px;
    
  }

  .blockman{
    width: 45%;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 60px;
    margin: auto;
    color: #Ff7514;
    border-radius: 10px;
  }

  .hrBlock{
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .buttonBlock{
    position: relative;
    top: 30px;
    padding: 10px;
    background-color: #Ff7514;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    border: 0;
  }

  .buttonBlock:hover{
    background-color: white;
    color: #Ff7514;
    transition: 0.5s;
  }

  .hr{
    margin-top: 200px;
    border: 2px solid #Ff7514;
    border-radius: 10px;
  }

  .textHome{
    color: white;
    display: inline;
    padding: 2px 5px;
    text-transform: uppercase;
  }


  .start{
    margin: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .block1, .block2, .block3 {
    padding: 80px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 10px;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .block1{
    background-color: #Ff7514;
    color: #F5F5F5;
    position: relative;
    bottom: 20px;
  }

  .block2{
    background-color: #151616;
    color: white;
    position: relative;
    top: 50px;

  }

  .block3{
    background-color: #151616;
    color: white;
    position: relative;
    left: 50px;
  }

.manifestazioni {
  padding: 20px;
}

.manifestazioni h1 {
  text-align: center;
  margin-bottom: 20px;
}

.disposizioneManifestazioni {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.manifestazioni-grid {
  display: grid;
  grid-template-columns: repeat(3, 300px); /* Fixed 3 columns of 300px each */
  gap: 2rem;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .manifestazioni-grid {
    grid-template-columns: repeat(2, 300px); /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .manifestazioni-grid {
    grid-template-columns: 300px; /* 1 column on small screens */
  }
}

.disposizioneManifestazioni img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.manifestazioneImg {
  max-width: 300px;
}

  .endHome {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #151616;
    padding: 30px;
    color: white;
    margin: 20px;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 10px;
  }

  .contactButton{
    position: relative;
    top: 20px;
    padding: 30px;
    border-radius: 10px;
    border: 0;
    font-size: x-large;
    background-color: #Ff7514;
    color: black;
    cursor: pointer;
    color: white;
    text-decoration: none;
  }

  .contactButton:hover{
    background-color: white;
    color: #Ff7514;
    transition: 1s;
  }

  .textEnd{
    font-size: large;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
  }

  @media (max-width: 1000px){
    .block {

      width: 80%;
      padding: 20px;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
      text-align: center;
      position: absolute;
      top: 0;
      color: #Ff7514;
      
    }

    .endHome {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      background-color: #151616;
      padding: 30px;
      color: white;
      margin: 20px;
      border-radius: 10px;
    }

    .contactButton{
      position: static;
      top: 20px;
      padding: 10px;
      border-radius: 10px;
      border: 0;
      font-size: x-large;
      background-color: #Ff7514;
      color: black;
      cursor: pointer;
      color: white;
      text-decoration: none;
    }

    .block1, .block2, .block3 {
      padding: 40px;
      padding-bottom: 60px;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
      border-radius: 10px;
      margin: 30px auto;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      
    }

    .disposizioneManifestazioni {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
      justify-items: center;
    }

    .manifestazioni{
      position: relative;
      bottom: 100px;
    }

    .textEnd{
      font-size: large;
      text-align: center;
      margin-bottom: 30px;
    }

    .blockman{
      width: 70%;
      padding: 0;
      box-shadow: 0px 0px 0 rgba(0,0,0,0.5);
      text-align: center;
      position: relative;
      bottom: 50px;
      margin: auto;
      color: #Ff7514;
    }

    .hr{
      margin-bottom: 60px;
    }


    .gallery {
      grid-template-columns: repeat(4, 1fr);
      max-width: 80%;
      justify-content: center;
      padding: 0 10px;
    }

    .containerGalleryText {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  
  
    .textDiv {
      max-width: 100%;
      padding: 10px;
      text-align: center;
    }
  
    .gallery-image {
      width: 100%;
      height: auto;
    }
  
    .flexDiv {
      flex-direction: column;
      align-items: center;
    }
  
    .colDiv {
      margin-bottom: 20px;
    }
   
  }


  


.bg {
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    position: fixed;
    width: 80%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    top: 20px;
    left: 10%;
}


.logo {
    max-width: 60px;
    margin-left: 50px;
}

.infop {
    display: flex;
    align-items: center;
    position: relative;
    
}

.nav-link {
    text-decoration: none;
    color: white;
    font-size: large;
    text-transform: uppercase;
    transition: color 0.3s ease;
}



.nav-link.active {
    
    font-weight: bold;
    color: #Ff7514;
}

.mr10 {
    margin: 10px;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: #Ff7514;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    right: 30px;
}

.logout-button {
    background: none;
    border: none;
    color: white;
    font-size: large;
    cursor: pointer;
    right: 30px;
}


@media (max-width: 1500px) {

    .bg {
      padding: 0 20px;
      background-color: transparent;
      top: 0;
      left: 0;
      width: 100%;
    }

    .logo {
      margin-left: 0;
    }

    .infop {
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        right: 0px;
        height: 100%;
        width: 70%;
        background-color: rgba(0, 0, 0, 0.753);
        border-radius: 0 10px 0 10px;
        padding: 100px 10px 200px 10px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }

    .infop.open {
        opacity: 1;
        visibility: visible;
    }

    .nav-link {
        margin: 10px;
        font-size: 18px;
        color: #Ff7514;
    }

    .menu-toggle {
        display: block;
        margin-right: 20px;
        top: 2px;
    }

    .menu-toggle:active {
        animation: rotate 1s linear;
    }

    @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
    }

    button:active {
        background-color: #f5f5f5;
        transform: scale(0.95);
    }

    .topPage {
        bottom: 70px;
    }

    .menu-toggle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        padding: 5px;
      }
      
      .menu-toggle .line {
        width: 30px;
        height: 3px;
        background-color: #Ff7514;
        margin: 4px 0;
        border-radius: 2px;
      }
      
      .menu-toggle .top,
      .menu-toggle .middle,
      .menu-toggle .bottom {
        position: absolute;
        transition: 0.3s ease-in-out;
      }
      
      .menu-toggle .top {
        top: 0;
      }
      
      .menu-toggle .middle {
        top: 12px;
      }
      
      .menu-toggle .bottom {
        top: 24px;
      }
      
}

.topPage {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    border: 0;
    padding: 4px 8px 4px 8px;
    background-color: #151616;
    border-radius: 10px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    z-index: 100;
}

.topPage:hover {
    transform: scale(1.1);
    background-color: #111111;



}



.edit-container {
  max-width: 800px;
  margin: 100px auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.089);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.edit-container h2 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-size: 2em;
  font-weight: 600;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: white;
  font-weight: 500;
  font-size: 0.95em;
}

.form-group input {
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1em;
  transition: all 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #Ff7514;
  box-shadow: 0 0 0 3px rgba(255, 117, 20, 0.1);
}

.form-group input[type="file"] {
  padding: 10px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.form-group input[type="file"]::-webkit-file-upload-button {
  background-color: #Ff7514;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.form-group input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #e66a12;
}

.existing-pdfs {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.existing-pdfs h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.1em;
}

.existing-pdfs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.existing-pdfs li {
  margin-bottom: 10px;
}

.existing-pdfs a {
  color: #Ff7514;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: color 0.3s ease;
}

.existing-pdfs a:before {
  content: "📄";
  margin-right: 8px;
}

.existing-pdfs a:hover {
  color: #e66a12;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.button-group button {
  flex: 1;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-group button[type="submit"] {
  background-color: #Ff7514;
  color: white;
}

.button-group button[type="submit"]:hover {
  background-color: #e66a12;
  transform: translateY(-2px);
}

.button-group button[type="button"] {
  background-color: #e0e0e0;
  color: #333;
}

.button-group button[type="button"]:hover {
  background-color: #d0d0d0;
  transform: translateY(-2px);
}

.error {
  background-color: #fee;
  color: #e33;
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .edit-container {
    margin: 20px;
    padding: 20px;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
  }
}

/* Loading Animation */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #Ff7514;
  font-size: 1.2em;
}

/* Hover Effects */
.form-group:hover label {
  color: #Ff7514;
}

/* Focus States */
input:focus {
  outline: none;
  border-color: #Ff7514;
  box-shadow: 0 0 0 3px rgba(255, 117, 20, 0.1);
}

/* Animation for Error Messages */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  75% { transform: translateX(10px); }
}

.error {
  animation: shake 0.5s ease-in-out;
}